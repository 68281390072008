import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import TextPageComponent from "../components/shared/text-page"
import { Link } from "gatsby"

const PolicyPage = () => (
  <Layout forceFixedHeader={true} page={"policy"}>
    <SEO title="Наши партнеры" noindex="noindex" />
    <TextPageComponent title={"Наши партнеры"}>
      <p>
        <b>Товарищество с ограниченной ответственностью «ТЕЗИС Центральная Азия»  (Сокращенное наименование - ТОО «ТЕЗИС Центральная Азия»)</b><br />
      </p>
      <p>Генеральный директор ТОО «ТЕЗИС Центральная Азия» Курманов Арлан Аскарбекович.</p>
      <p><b>БИН</b> 180540037021 </p>
      <p><b>Адрес:</b> 050012, Республика Казахстан, г.Алматы, Алмалинский р-н, пр. Сейфуллина 502, Бизнес Центр «TURAR», офис 706</p>
      <p><b>Банковские реквизиты:</b></p>
      <p>KZ748562203116506313 – KZT</p>
      <p>KZ968562203316506337 – RUB</p>
      <p>В АО "Банк ЦентрКредит"</p>
      <p><b>БИК</b> KCJBKZKX</p>
    </TextPageComponent>
  </Layout>
)

export default PolicyPage
